import { useEffect } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { useServiciosFijos } from '~/store/Query/api-fijo';
import productHelper from '~/helpers/product';
import { SwalModal } from '~/store/Modals/sweet';
import { currencyFormat } from '~/helpers/formatter';
import { OFERTA_RENOVACION } from '~/store/States/global';

export const Modal = config => SwalModal({
  closeButtonHtml: '<i class="fas fa-times red-text modal-close"></i>',
  ...config,
});

const fijoState = hookstate({
  texto: {
    9: {
      precioEtiqueta: 'Precio del plan',
      precioPrefijo: '',
      precioSufijo: ' AL MES',
      seleccionar: 'Seleccionar',
      detalle: 'Ver detalle',
    },
    10: {
      precioEtiqueta: '',
      precioPrefijo: '',
      precioSufijo: '',
      seleccionar: 'Seleccionar',
      detalle: 'Ver detalle',
    },
    aviso: 'Debes elegir un plan antes de seleccionar uno o más servicios.',
    total_prefijo: 'Total',
    total_sufijo: ' /mes',
    boton_continuar: 'Continuar',
    form_titulo: 'Hazlo fácil, seguro y ágil',
    form_subtitulo: 'Ahora puedes contratar servicios fijos sin salir de casa. Envíanos tus datos y pronto de contactaremos.',
    form_datos: 'Datos de contratación',
    form_resumen: 'Resumen de contratación',
    form_boton: 'Enviar solicitud',
    modal_titulo: 'Gracias por ingresar tus datos',
    modal_texto: 'Muy pronto te estaremos contactando',
    modal_boton: 'Volver a tienda',
    modal_icono: '/cdn/espots/cobertura/icono_exito.png',
    aviso_legal: 'Aplican restricciones.',
    ubicacion_titulo: 'Ingresa tu dirección',
    ubicacion_subtitulo: 'Ingresa tu dirección para verificar la cobertura en tu zona y brindarte una mejor experiencia.',
    ubicacion_direccion: 'Dirección',
    ubicacion_usar: 'Ubicación actual',
    ubicacion_placeholder: 'Ingresa dirección',
    ubicacion_verificar: 'Verificar dirección en el mapa',
    ubicacion_error: 'No encontramos tu ubicación. Ingresa una nueva dirección o utiliza el mapa.',
    ubicacion_boton_cancelar: 'Cancelar',
    ubicacion_boton_confirmar: 'Continuar',
    cobertura_solicitud: 'Completa la solicitud',
    cobertura_exito: 'Envíanos tus datos y pronto un agente te contactará para dar seguimiento a tu contratación.',
    cobertura_error: 'La opción solicitada no está disponible para tu dirección. Puedes ingresar tus datos para brindarte otras opciones.',
    cobertura_plan: 'Plan seleccionado:',
    cobertura_precio: 'Precio de plan:',
    cobertura_precio_sufijo: ' al mes',
  },
});

export const planSelected = hookstate([]);

const addonsSelected = hookstate({});

const totalSelected = hookstate(['0', '00']);

export const categoriaFijoState = hookstate();

export const useFijoState = () => {
  const config = useServiciosFijos();
  useEffect(() => {
    if (config.data?.ok) {
      const info = config.data.body.data;
      const data = info.texto;
      fijoState.merge({
        ...info,
        texto: {
          ...fijoState.texto.get({ noproxy: true }),
          ...data,
          9: {
            precioEtiqueta: data.plan_precio_etiqueta,
            precioPrefijo: data.plan_precio_prefijo,
            precioSufijo: data.plan_precio_sufijo,
            seleccionar: data.plan_seleccionar,
            detalle: data.plan_detalle,
          },
          10: {
            precioEtiqueta: data.addon_precio_etiqueta,
            precioPrefijo: data.addon_precio_prefijo,
            precioSufijo: data.addon_precio_sufijo,
            seleccionar: data.addon_seleccionar,
            detalle: data.addon_detalle,
          },
        },
      });
    }
  }, [config.data]);
  return useHookstate(fijoState);
};

export const usePlanSelected = () => useHookstate(planSelected);
export const useAddonsSelected = () => useHookstate(addonsSelected);
export const useTotalSelected = () => useHookstate(totalSelected);

export const productInfo = (item) => {
  const name = item.locales[process.env.NEXT_PUBLIC_LOCALE].name.replace(/<br>/, '\n').replace(/<br\/>/, '\n').replace(/\\n/, '\n');
  const simplename = name.replace(/\\n/g, '');
  const offer = item.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price / 100;
  const attrMap = productHelper.convertAttributes(item.attributes);
  const code = attrMap['attr-conf-friendly-url'] || item.locales[process.env.NEXT_PUBLIC_LOCALE].seo.name || item.part_number;
  const format = currencyFormat(offer, 1);
  const isOfertaNew = attrMap['attr-conf-tipo-oferta']?.toUpperCase() !== OFERTA_RENOVACION;
  const tipoPlan = attrMap['filt-tipo-plan'] || attrMap['filt-tipo-de-plan'];
  const modalidad = attrMap['filt-modalidad'];
  return {
    name,
    simplename,
    offer,
    code,
    format,
    isOfertaNew,
    tipoPlan,
    modalidad,
  };
};

export const claroHogarState = hookstate({});

export const ubicacionState = hookstate({
  address: '',
  show: false,
  edited: false,
  found: false,
});

export const coberturaState = hookstate({
  ok: false,
  fields: {
    adicional4: '',
    adicional5: '',
    adicional6: '',
    adicional7: '',
  },
});

export const selectedState = hookstate(null);

export const pasoState = hookstate(1);

export const geolocation = () => new Promise((resolve, reject) => {
  if (navigator.geolocation) {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition((position) => {
      const crd = position.coords;

      const location = {
        lat: crd.latitude,
        lng: crd.longitude,
        granted: true,
      }
      ubicacionState.merge(location);
      resolve(location);
    }, (error) => {
      console.error(error);
      ubicacionState.merge({ granted: false });
      reject(error);
    }, options);
  } else {
    reject(new Error('Geolocalización no soportada en este navegador.'));
  }
});
